import {graphql} from 'gatsby';
import * as React from 'react';
import {useTranslation} from 'react-i18next';
import MainLayout from '../layouts/main';
import constants from '../utils/constants';
import withI18next from "../../plugins/gatsby-plugin-i18next/withI18next";

interface Props {
  data: {
    site: {
      siteMetadata: {
        title: string,
      },
    },
  };
}

function Page(props: Props) {

  const {data} = props;
  const {t} = useTranslation();

  return (

    <MainLayout currentPage={constants.PAGE_PRIVACY_POLICY}>
      <h1>{t(constants.PAGE_PRIVACY_POLICY + '-title')}</h1>
      <p>{t(constants.PAGE_PRIVACY_POLICY + '-last-updated', {date: '2019-02-23'})}</p>
      <p>{t(constants.PAGE_PRIVACY_POLICY + '-intro', {title: data.site.siteMetadata.title})}</p>
      <h2>{t(constants.PAGE_PRIVACY_POLICY + '-logs')}</h2>
      <p>{t(constants.PAGE_PRIVACY_POLICY + '-logs-desc', {title: data.site.siteMetadata.title})}</p>
      <h2>{t(constants.PAGE_PRIVACY_POLICY + '-cookies')}</h2>
      <p>{t(constants.PAGE_PRIVACY_POLICY + '-cookies-desc', {title: data.site.siteMetadata.title})}</p>
      <h2>{t(constants.PAGE_PRIVACY_POLICY + '-third-party')}</h2>
      <p>{t(constants.PAGE_PRIVACY_POLICY + '-third-party-intro', {title: data.site.siteMetadata.title})}</p>
      <h3>{t(constants.PAGE_PRIVACY_POLICY + '-third-party-analytics', {title: data.site.siteMetadata.title})}</h3>
      <p
        dangerouslySetInnerHTML={{
          __html: t(constants.PAGE_PRIVACY_POLICY + '-third-party-analytics-desc',
            {title: data.site.siteMetadata.title}),
        }}
      />
      <h3>{t(constants.PAGE_PRIVACY_POLICY + '-third-party-advertising', {title: data.site.siteMetadata.title})}</h3>
      <p
        dangerouslySetInnerHTML={{
          __html: t(constants.PAGE_PRIVACY_POLICY + '-third-party-advertising-desc',
            {title: data.site.siteMetadata.title}),
        }}
      />
      <h3>{t(constants.PAGE_PRIVACY_POLICY + '-changes', {title: data.site.siteMetadata.title})}</h3>
      <p>{t(constants.PAGE_PRIVACY_POLICY + '-changes-desc', {title: data.site.siteMetadata.title})}</p>
      <h3>{t(constants.PAGE_PRIVACY_POLICY + '-questions', {title: data.site.siteMetadata.title})}</h3>
      <p
        dangerouslySetInnerHTML={{
          __html: t(constants.PAGE_PRIVACY_POLICY + '-questions-desc', {title: data.site.siteMetadata.title}),
        }}
      />
    </MainLayout>
  );
}

export default withI18next()(Page);

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
